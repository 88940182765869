<template>
  <b-modal
    id="add-user-modal"
    v-model="openModal"
    size="md"
    :title="$t('modals.selectScenario.title')"
  >
    <div>
      <Multiselect
        v-model="selectedScenario"
        :options="options"
        track-by="id"
        label="display_name"
        select-label=""
        selected-label=""
        deselect-label=""
        :allow-empty="false"
        :searchable="false"
      />
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="hide"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :class="{ disabled: !selectedScenario }"
          variant="primary"
          class="float-right"
          @click="selectScenario"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'SelectScenario',

  components: {
    Multiselect
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    context: {
      type: String,
      default: 'create'
    },
    scenarios: {
      type: Array,
      default: () => { return []; }
    }
  },

  data() {
    return {
      openModal: false,
      loading: false,
      options: [],
      selectedScenario: null
    };
  },

  watch: {
    open: function(newValue) {
      this.resetModal();
      this.openModal = newValue;
      if (newValue) {
        if (this.scenarios.length) {
          this.options = this.scenarios;
          this.selectedScenario = this.options[0];
        }
      }
    }
  },

  methods: {
    resetModal() {
      this.openModal = false;
    },
    selectScenario() {
      this.$emit(this.context, this.selectedScenario.id);
      this.$emit('hide');
    },
    hide() {
      this.$emit('hide');
    }
  }
};
</script>

<style lang="less" scoped>

h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

</style>
