<template>
  <div>
    <div class="form-row">
      <div class="form-group col-6">
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('resource.format.label') }}
        </label>
        <p class="label-help">
          {{ $t('resource.format.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Format Href"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="isRequired ? classes : ''"
          >
            <Multiselect
              v-model="form.format"
              :options="resourceDataFormats"
              track-by="codename"
              label="display_name"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.format.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <!-- <div
        v-if="isMain"
        class="form-group col-6"
      >
        <label style="margin-top: 1em;">
          {{ $t('resource.scheduler.label') }}
        </label>
        <p>
          {{ $t('resource.scheduler.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Frequence FTP"
        >
          <div
            class="control"
          >
            <Multiselect
              v-model="form.scheduler"
              :options="schedulers"
              track-by="id"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.scheduler.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div> -->
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          URL
        </label>
        <p>{{ $t('resource.url.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="URL"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="isRequired ? classes : ''"
          >
            <input
              v-model="form.href"
              v-sanitize
              class="form-control"
              type="text"
              placeholder="https://"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'HrefCreator',

  components: {
    Multiselect,
    ValidationProvider
  },

  props: {
    required: {
      type: Boolean,
      default: true
    },
    isMain: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      isRequired: true,
      form: {
        type: 'href',
        format: null,
        // scheduler: null,
        href: null
      }
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
      'schedulers'
    ])
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    form: {
      deep: true,
      handler(newValue) {
        if (newValue.format || newValue.href) {
          this.setRequired(true);
        } else {
          this.setRequired(false);
        }
      }
    },
    'form.href': function() {
      this.setResourceURL();
    },
    'form.format': function() {
      this.setResourceURL();
    }
  },

  created() {
    this.isRequired = this.required;
    if (this.resourceDataFormats.length === 0) {
      this.GET_RESOURCE_DATA_FORMATS();
    }
    if (this.schedulers.length === 0) {
      this.GET_SCHEDULERS();
    }
  },

  methods: {
    ...mapActions('resources', [
      'GET_RESOURCE_DATA_FORMATS',
      'GET_SCHEDULERS'
    ]),

    setRequired(e) {
      this.isRequired = e;
      this.$emit('set', {
        required: this.isRequired
      });
    },

    setResourceURL() {
      this.$emit('set', {
        form: this.form,
        required: this.isRequired
      });
    }
  }
};
</script>
