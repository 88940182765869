var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource_geographical_layer"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('geographiclayer__display_name', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.geographicalDisplayName'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('geographiclayer__display_name', _vm.formConfig.requiredFields),"name":_vm.$t('resource.geographicalDisplayName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.geographiclayerDisplayName),expression:"form.geographiclayerDisplayName"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.geographicalDisplayName')},domProps:{"value":(_vm.form.geographiclayerDisplayName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "geographiclayerDisplayName", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('column_x', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.XLon'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('column_x', _vm.formConfig.requiredFields),"name":_vm.$t('resource.XLon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.columnX),expression:"form.columnX"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.XLon')},domProps:{"value":(_vm.form.columnX)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "columnX", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3305139196)})],1)]):_vm._e(),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('column_y', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.YLon'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('column_y', _vm.formConfig.requiredFields),"name":_vm.$t('resource.YLon')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.columnY),expression:"form.columnY"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.YLon')},domProps:{"value":(_vm.form.columnY)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "columnY", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,312327997)})],1)]):_vm._e(),(_vm.isXY)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('crs', _vm.formConfig.requiredFields) ? 'required' : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.crs'))+" ")]),_c('ValidationProvider',{ref:"geographical_display_label",attrs:{"rules":_vm.isFieldRequired('crs', _vm.formConfig.requiredFields),"name":_vm.$t('resource.crs')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.crs),expression:"form.crs"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('resource.crs')},domProps:{"value":(_vm.form.crs)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "crs", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1319801643)})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }