<template>
  <div :class="disabled ? 'disabled' : ''">
    <Multiselect
      ref="multiselect"
      v-model="selection"
      style="margin-top: 0.5em;"
      class="search-datasets"
      :options="results"
      :options-limit="limit"
      :allow-empty="true"
      track-by="id"
      label="display_name"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="placeholder"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="true"
      @search-change="search"
      @select="select"
      @close="close"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="selection = null; $refs.multiselect.deactivate();"
        >
          <b-icon-x
            v-if="!disabled"
            font-scale="2"
          />
        </div>
      </template>
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noResult') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapMutations, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';

export default {
  name: 'SearchDatasets',

  components: {
    Multiselect
  },

  props: {
    dataset: {
      type: Number,
      default: null
    },
    usergroup: {
      type: Object,
      default: () => { return {}; }
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    },
    recordTypes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsList',
      'currentDataset'
    ]),
  },

  watch: {
    dataset: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          try {
            this.loading = true;
            const dataset = await datasetsAPI.getDataset(newValue);
            if (dataset) {
              this.selection = dataset;
            }
            this.loading = false;
          } catch (err) {
            console.error(err);
            this.loading = false;
          }
        }
      }
    },
    usergroup: {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.loading = true;
          try {
            await this.getDatasetsList();
            this.loading = false;
          } catch {
            this.loading = false;
          }
        }
      }
    },
    recordTypes: {
      deep: true,
      async handler() {
        this.loading = true;
        try {
          await this.getDatasetsList();
          if (this.dataset && this.dataset.id) {
            this.selection = this.dataset;
          }
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
    },
    text: function(newValue) {
      this.loading = true;
      this.SEARCH_DATASETS_LIST({
        text: newValue,
        ordering: {
          direction: '',
          field: 'display_name'
        },
        recordTypes: this.recordTypes
      })
        .then(() => {
          this.results = this.datasetsList.filter(el => {
            if (this.currentDataset.id) {
              return el.id !== this.currentDataset.id &&
                      !this.currentDataset.children.find(child => child.id === el.id);
            } else {
              return true;
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  async created() {
    this.loading = true;
    try {
      this.SET_DATASETS_FILTERS({
        filter: 'perms',
        value: null
      });
      await this.getDatasetsList();
      if (this.dataset && this.dataset.id) {
        this.selection = this.dataset;
      }
      this.loading = false;
    } catch {
      this.loading = false;
    }
  },

  mounted() {
    document.addEventListener('click', this.$refs.multiselect.deactivate());
  },

  methods: {
    ...mapMutations('datasets', [
      'SET_DATASETS_FILTERS',
      'SET_IS_DATASETSLIST_SEARCHED'
    ]),
    ...mapActions('datasets', [
      'SEARCH_DATASETS_LIST',
      'GET_DATASETS_LIST'
    ]),

    async getDatasetsList() {
      await this.GET_DATASETS_LIST({
        direction: '',
        field: 'display_name',
        recordTypes: this.recordTypes
      });
      this.results = this.datasetsList.filter(el => {
        if (this.currentDataset.id) {
          return el.id !== this.currentDataset.id &&
                  !this.currentDataset.children.find(child => child.id === el.id);
        } else {
          return true;
        }
      });
    },

    search(text) {
      this.text = text;
    },

    select(e) {
      this.$emit('select', e);
      this.SET_IS_DATASETSLIST_SEARCHED({
        isSearched: false,
        text: null
      });
    },
    close() {
      this.$emit('close', this.selection);
      this.SET_IS_DATASETSLIST_SEARCHED({
        isSearched: false,
        text: null
      });
    }
  }
};
</script>

<style lang="less" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
