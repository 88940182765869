<template>
  <div class="card-container">
    <h5 v-if="dataset.display_name">
      {{ dataset.display_name }}
    </h5>
    <h5
      v-else
      class="placeholder"
    >
      {{ $t('words.new') }}
    </h5>
    <div class="codename">
      <a
        :href="toPortail"
        target="_blank"
      >
        {{ dataset.codename }}
      </a>
    </div>

    <ImportImage
      :type="'image'"
      :name="'dataset-thumbnail'"
      :title="thumbnail ? thumbnail.name : null"
      :file="thumbnail ? thumbnail.url : null"
      :show-file-name="false"
      :show-input="showImgInput"
      class="thumbnail-selector"
      @update="setThumbnail"
    />
    <p class="label-help">
      {{ $t('forms.datasets.thumbnail.help') }}
    </p>
  </div>
</template>

<script>
import ImportImage from '@/components/ImportImage.vue';

export default {
  name: 'DatasetCard',

  components: {
    ImportImage
  },

  props: {
    dataset: {
      type: Object,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      thumbnail: null,
      showImgInput: false
    };
  },

  computed: {
    thumbnailURL() {
      if (this.dataset.thumbnail && this.dataset.thumbnail.url) {
        return new URL(this.dataset.thumbnail.url, process.env.VUE_APP_DOMAIN).href + `?${new Date().getTime()}`;
      } else {
        return '';
      }
    },

    toPortail() {
      return `${process.env.VUE_APP_PORTAIL_ROUTE}jeux-de-donnees/${this.dataset.codename}`;
    }
  },

  watch: {
    dataset: {
      deep: true,
      handler(newValue) {
        if (newValue.thumbnail) {
          this.thumbnail = newValue.thumbnail;
        }
      }
    },
  },

  mounted() {
    if (this.dataset.thumbnail) {
      this.thumbnail = this.dataset.thumbnail;
    }
  },

  methods: {
    setThumbnail(e) {
      if (e) {
        const formData = new FormData();
        formData.append('file', e);
        this.thumbnail = formData;
        this.$emit('set', this.thumbnail);
      } else {
        this.thumbnail = e;
        this.$emit('set', this.thumbnail);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.placeholder {
  color: rgb(196, 196, 196);
}
</style>
