<template>
  <div>
    <div class="form-row">
      <b-overlay
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
        style="z-index: 1040;"
      />
      <div
        class="form-group col-6"
      >
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          {{ $tc('resource.geoserver.workspace.label', 1) }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :name="`Layer workspace ${usedFor}`"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="form.workspace"
              style="margin-top: 0.5em;"
              class="search-usergroups"
              :options="workspaces"
              :allow-empty="true"
              track-by="name"
              label="name"
              :reset-after="false"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="true"
              :placeholder="$t('resource.geoserver.workspace.placeholder')"
              :loading="workspacesLoading"
              :disabled="workspacesLoading"
              :clear-on-select="false"
              @select="selectWorkspace"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div
        v-if="form.workspace"
        class="form-group col-6 layers-layergroups"
      >
        <b-form-group>
          <b-form-radio-group
            id="btn-radios"
            v-model="layersORlayergroups"
            :options="[
              { text: $tc('resource.geoserver.layer.label', 2), value: 'restlayers' },
              { text: $tc('resource.geoserver.layergroup.label', 2), value: 'restlayergroups' }
            ]"
            button-variant="outline-primary"
            name="radio-btn-outline"
            buttons
          />
        </b-form-group>
      </div>
    </div>
    <div
      v-if="form.workspace"
      class="form-row"
    >
      <div
        :class="{ disabled: layersORlayergroups !== 'restlayers'}"
        class="form-group col-6"
      >
        <label 
          :class="{ required: layersORlayergroups === 'restlayers'}"
          style="margin-top: 1em;"
        >
          {{ $tc('resource.geoserver.layer.label', 1) }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :name="`Layer layer ${usedFor}`"
          :rules="`${isRequired && layersORlayergroups === 'layers' ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="form.layer"
              style="margin-top: 0.5em;"
              class="search-usergroups"
              :options="layers"
              :allow-empty="true"
              track-by="name"
              label="name"
              :reset-after="false"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="true"
              :placeholder="$t('resource.geoserver.layer.placeholder')"
              :loading="layersLoading"
              :disabled="layersLoading"
              :clear-on-select="false"
              @select="selectLayer"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div
        :class="{ disabled: layersORlayergroups !== 'restlayergroups'}"
        class="form-group col-6"
      >
        <label 
          :class="{ required: layersORlayergroups === 'restlayergroups'}"
          style="margin-top: 1em;"
        >
          {{ $tc('resource.geoserver.layergroup.label', 1) }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :name="`Layer layergroup ${usedFor}`"
          :rules="`${layersORlayergroups === 'restlayergroups' && isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="form.layergroup"
              style="margin-top: 0.5em;"
              class="search-usergroups"
              :options="layergroups"
              :allow-empty="true"
              track-by="name"
              label="name"
              :reset-after="false"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="true"
              :placeholder="$t('resource.geoserver.layergroup.placeholder')"
              :loading="layergroupsLoading"
              :disabled="layergroupsLoading"
              :clear-on-select="false"
              @select="selectLayergroup"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';
import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'GeoserverCreator',

  components: {
    Multiselect,
    ValidationProvider
  },

  props: {
    required: {
      type: Boolean,
      default: true
    },
    usedFor: {
      type: String,
      default: 'resource'
    },
    trigger: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      workspacesLoading: false,
      layersLoading: false,
      layergroupsLoading: false,
      isRequired: false,
      layersORlayergroups: 'restlayers',

      form: {
        type: 'geoserver',
        layerType: null,
        workspace: null,
        layer: null,
        layergroup: null
      }
    };
  },

  computed: {
    ...mapState('resources/geoserver', [
      'workspaces',
      'layers',
      'layergroups'
    ])
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    trigger(newValue) {
      if (newValue && this.workspaces.length === 0) {
        this.workspacesLoading = true;
        this.GET_WORKSPACES()
          .then(() => {
            this.workspacesLoading = false;
          })
          .catch(() => {
            this.workspacesLoading = false;
          });
      }
    },
    form: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.workspace.name !== oldValue.workspace.name) {
          this.form.layer = null;
          this.form.layergroup = null;
        }
        if (newValue.workspace) {
          this.isRequired = true;
        } else {
          this.isRequired = false;
        }
        this.$emit('set', {
          displayName: newValue.layer ? newValue.layer.name : newValue.layergroup ? newValue.layergroup.name : null,
          form: {
            ...newValue,
          },
          required: this.isRequired
        });
      }
    },
    'form.workspace': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.name && oldValue && oldValue.name && newValue.name !== oldValue.name) {
          this.form.layer = null;
          this.form.layergroup = null;
        }
      }
    },
    layersORlayergroups(newValue) {
      if (newValue === 'restlayers') {
        this.form.layergroup = null;
      }
      if (newValue === 'restlayergroups') {
        this.form.layer = null;
      }
    }
  },

  mounted() {
    if (!this.workspaces || (this.workspaces && this.workspaces.length === 0)) {
      this.workspacesLoading = true;
      this.GET_WORKSPACES()
        .then(() => {
          this.workspacesLoading = false;
        })
        .catch(() => {
          this.workspacesLoading = false;
        });
    }
  },

  methods: {
    ...mapMutations('resources/geoserver', [
      'SET_SELECTED_WORKSPACE'
    ]),
    ...mapActions('resources/geoserver', [
      'GET_WORKSPACES',
      'GET_LAYERS',
      'GET_LAYERGROUPS'
    ]),
    selectWorkspace(e) {
      this.SET_SELECTED_WORKSPACE(e);
      this.layersLoading = true;
      this.layergroupsLoading = true;
      this.GET_LAYERS()
        .then(() => {
          this.layersLoading = false;
        })
        .catch(() => {
          this.layersLoading = false;
        });
      this.GET_LAYERGROUPS()
        .then(() => {
          this.layergroupsLoading = false;
        })
        .catch(() => {
          this.layergroupsLoading = false;
        });
    },
    selectLayer() {
      this.form.layerType = this.layersORlayergroups;
    },
    selectLayergroup() {
      this.form.layerType = this.layersORlayergroups;
    },
  }
};
</script>

<style lang="less" scoped>
.layers-layergroups {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  fieldset {
    margin-bottom: 2px;
  }
}
</style>
