import { render, staticRenderFns } from "./ResourceCreator.vue?vue&type=template&id=e98c1e12&scoped=true"
import script from "./ResourceCreator.vue?vue&type=script&lang=js"
export * from "./ResourceCreator.vue?vue&type=script&lang=js"
import style0 from "./ResourceCreator.vue?vue&type=style&index=0&id=e98c1e12&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e98c1e12",
  null
  
)

export default component.exports